import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image/withIEPolyfill'
import { mq, util } from 'src/styles'

const StyledImage = styled(Img)`
	${ ({ small, medium, type }) => `
		> div {
			${ medium ? `
				${ mq.largeAndBelow } {
					padding-bottom: ${ 100.0 / medium.fluid.aspectRatio }% !important;
				}
			` : `` }
			${ small ? `
				${ mq.mediumAndBelow } {
					padding-bottom: ${ 100.0 / small.fluid.aspectRatio }% !important;
				}
			` : `` }
		}
		${ type === 'web' || type === 'mobile' ? `
			box-shadow: 0 20px 30px rgba(0, 0, 0, .15);
			border-radius: 3px;
			overflow: hidden;
			img {
				border-radius: 3px;
			}
		` : ` ` }
		${ type === 'mobile' ? `
			img {
				${ util.responsiveStyles('border-radius', 32, 26, 22, 22) }
			}
			${ util.responsiveStyles('border-radius', 32, 26, 22, 22) }
		` : ` ` }
	` }
	img {
		transition: opacity 1s ease-in-out !important;
	}
`

const ResponsiveImage = ({ image, small, medium, large, className, type, customSizes, loading, altText }) => {
	if (small || medium || large || image) {
		let source = null
		if (image) {
			source = image.fluid
			if (customSizes) {
				source.sizes = customSizes
			}
		} else {
			source = []

			if (large) {
				source.push({
					...large.fluid,
					media: `(min-width: ${ mq.largeBreakpoint + 1 }px)`,
					sizes: customSizes || null
				})
			}

			if (medium) {
				source.push({
					...medium.fluid,
					media: `(min-width: ${ mq.mediumBreakpoint + 1 }px)`,
					sizes: customSizes || null
				})
			}

			if (small) {
				source.push({
					...small.fluid,
					media: `(min-width: 1px)`,
					sizes: customSizes || null
				})
			}
		}

		if (!source) {
			return false
		}

		return (
			<StyledImage
				className={className}
				fluid={source}
				placeholderStyle={{ display: 'none' }}
				durationFadeIn={1000}
				type={type}
				loading={loading}
				customSizes={customSizes}
				small={small}
				medium={medium}
				large={large}
				alt={altText}
			/>
		)
	} else {
		return false
	}
}

const Image = ({ useMultipleImages, small, medium, large, image, className, type, sizes, loading, altText }) => (
	<ResponsiveImage
		image={image}
		small={small}
		medium={medium}
		large={large}
		className={className}
		type={type}
		customSizes={sizes}
		loading={loading}
		altText={altText}
	/>
)

Image.defaultProps = {
	loading: 'lazy'
}

export {
	ResponsiveImage,
	Image as default
}
