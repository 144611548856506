import React from 'react'
import styled from '@emotion/styled'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import BalanceText from 'react-balance-text'

const RichTextWrapper = styled.div`
	> h1:first-of-type,
	> h2:first-of-type,
	> h3:first-of-type,
	> h4:first-of-type {
		margin-top: 0;
	}
	// > *:last-child {
	// 	margin-bottom: 0;
	// }
	white-space: pre-wrap;
	* {
		white-space: pre-wrap;
	}
`

const Heading1 = ({ children }) => <h1><BalanceText>{children}</BalanceText></h1>

const adjustedText = text => {
	let parsedHeadline = text[0]
	console.log(text[0])
	parsedHeadline = text[0]
		.replace(new RegExp('ffi', 'g'), "<span style='letter-spacing: 0'>ffi</span>")
		.replace(new RegExp('ff', 'g'), "<span style='letter-spacing: 0'>ff</span>")
		.replace(new RegExp('fi', 'g'), "<span style='letter-spacing: 0'>fi</span>")
		.replace(new RegExp('fl', 'g'), "<span style='letter-spacing: 0'>fl</span>")
		.replace(new RegExp('⓿', 'g'), "<span style='letter-spacing: -.05em; display: inline-block; vertical-align: middle; margin-bottom: -.175em;'>⓿</span>")
		.replace(new RegExp('❶', 'g'), "<span style='letter-spacing: -.05em; display: inline-block; vertical-align: middle; margin-bottom: -.175em;'>❶</span>")
		.replace(new RegExp('❷', 'g'), "<span style='letter-spacing: -.05em; display: inline-block; vertical-align: middle; margin-bottom: -.175em;'>❷</span>")
		.replace(new RegExp('❸', 'g'), "<span style='letter-spacing: -.05em; display: inline-block; vertical-align: middle; margin-bottom: -.175em;'>❸</span>")
		.replace(new RegExp('❹', 'g'), "<span style='letter-spacing: -.05em; display: inline-block; vertical-align: middle; margin-bottom: -.175em;'>❹</span>")
		.replace(new RegExp('❺', 'g'), "<span style='letter-spacing: -.05em; display: inline-block; vertical-align: middle; margin-bottom: -.175em;'>❺</span>")
		.replace(new RegExp('❻', 'g'), "<span style='letter-spacing: -.05em; display: inline-block; vertical-align: middle; margin-bottom: -.175em;'>❻</span>")
		.replace(new RegExp('❼', 'g'), "<span style='letter-spacing: -.05em; display: inline-block; vertical-align: middle; margin-bottom: -.175em;'>❼</span>")
		.replace(new RegExp('❽', 'g'), "<span style='letter-spacing: -.05em; display: inline-block; vertical-align: middle; margin-bottom: -.175em;'>❽</span>")
		.replace(new RegExp('❾', 'g'), "<span style='letter-spacing: -.05em; display: inline-block; vertical-align: middle; margin-bottom: -.175em;'>❾</span>")
		.replace(new RegExp('❿', 'g'), "<span style='letter-spacing: -.05em; display: inline-block; vertical-align: middle; margin-bottom: -.175em;'>❿</span>")
	return parsedHeadline
}

const options = {
	renderMark: {
		[MARKS.BOLD]: text => <b>{text}</b>,
	},
	renderNode: {
		[BLOCKS.HEADING_1]: (node, children) => <Heading1>{adjustedText(children)}</Heading1>,
		[BLOCKS.HEADING_2]: (node, children) => <h2 dangerouslySetInnerHTML={{ __html: adjustedText(children) }}/>,
		[BLOCKS.HEADING_3]: (node, children) => <h3 dangerouslySetInnerHTML={{ __html: adjustedText(children) }}/>,
		[BLOCKS.HEADING_4]: (node, children) => <h4 dangerouslySetInnerHTML={{ __html: adjustedText(children) }}/>,
		[INLINES.HYPERLINK]: (node, children) => <a href={node.data.uri} target="_blank">{children}</a>,
	}
}

const ContentfulRichText = ({ richText, className }) => (
	<RichTextWrapper className={className}>
		{documentToReactComponents(richText, options)}
	</RichTextWrapper>
)

// ContentfulRichText.defaultProps = {
// 	richText: richText
// }

export default ContentfulRichText
