import React, { Component } from 'react'
import styled from '@emotion/styled'
import { colors, animations } from 'src/styles'
import { MdArrowForward } from 'react-icons/md'
import { Link as ScrollLink } from 'react-scroll'

import { Link as GatsbyLink } from 'gatsby'

const LinkStyles = (linktype, settheme) => `
	font-size: inherit;
	text-decoration: none;
	cursor: pointer;
	${ linktype === 'textLink' || linktype === 'color' || linktype === 'arrowLink' ? `
		position: relative;
		border: none;
		color: ${ colors[settheme] };
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 100%;
			left: -.01em;
			right: -.01em;
			height: 2px;
			background: ${ colors[settheme] };
			transform: scaleX(0);
			transform-origin: right center;
			transition: transform ${ animations.mediumSpeed } ease-in-out;
		}
		&:hover {
			&:after {
				transform-origin: left center;
				transform: scaleX(1);
			}
		}
	` : `` }
	${ linktype === 'color' ? `
		color: ${ colors[settheme] };
	` : `` }
`

const StyledLinkElement = styled.a`
	${ ({ linktype, settheme }) => `
		${ LinkStyles(linktype, settheme) }
	` }
`

const StyledGatsbyLink = styled(GatsbyLink)`
	${ ({ linktype, settheme }) => `
		${ LinkStyles(linktype, settheme) }
	` }
`
const StyledScrollLink = styled(ScrollLink)`
	${ ({ linktype, settheme }) => `
		${ LinkStyles(linktype, settheme) }
	` }
`

const ArrowIcon = styled(MdArrowForward)`
	display: inline-block;
	vertical-align: middle;
	margin-left: .3em;
	margin-top: -2px;
	margin-right: -2px;
	${ ({ external }) => external && `
		transform: rotate(-45deg);
		margin-top: 0;
	` }
`

class Link extends Component {
	render () {
		const { to, external, target, children, className, style, settheme } = this.props
		const externalLink = external.toString()
		if (externalLink === 'true') {
			return (
				<StyledLinkElement
					className={className}
					href={to}
					target={target}
					linktype={style}
					settheme={settheme}
				>
					{children}
					{style === 'arrowLink' && (
						<ArrowIcon size={18} external={externalLink}/>
					)}
				</StyledLinkElement>
			)
		}	else if (to.startsWith('#')) {
			return (
				<StyledScrollLink
					className={className}
					spy={true}
          smooth="true"
          to={to.substring(1)}
          duration={scrollDistance => scrollDistance / 2}
					linktype={style}
					settheme={settheme}
				>
					{children}
					{style === 'arrowLink' && (
						<ArrowIcon size={18}/>
					)}
				</StyledScrollLink>
			)
		} else {
			return (
				<StyledGatsbyLink
					className={className}
					to={to}
					linktype={style}
					settheme={settheme}
				>
					{children}
					{style === 'arrowLink' && (
						<ArrowIcon size={18} />
					)}
				</StyledGatsbyLink>
			)
		}
	}
}

Link.defaultProps = {
	to: '#',
	external: false,
	target: '_blank',
	style: 'none',
	settheme: 'mainColor'
}

export default Link
