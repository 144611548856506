import React, { Component } from 'react'
import styled from '@emotion/styled'
import LogoNav from 'src/components/LogoNav'
import Grid from 'src/components/Grid'
import Image from 'src/components/Image'
import ConditionalRender from 'src/components/ConditionalRender'
import ContentfulRichText from 'src/components/ContentfulRichText'
import TextLockup from 'src/components/TextLockup'
import ThemeSelector from 'src/components/ThemeSelector'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import withSizes from 'react-sizes'
import { colors, typography, animations, mq, util } from 'src/styles'
import MobileDetect from 'mobile-detect'
import Video from 'src/components/Video'

const Wrapper = styled.div`
	position: relative;
	${ ({ media }) => media && `
		background: ${ colors.black };
		color: ${ colors.bgColor };
	` }
	${ ({ settheme }) => settheme === 'blue' && `
		z-index: 11;
	` }
`

const IntroText = styled(TextLockup)`
	${ util.responsiveStyles('max-width', 1000, 950, 800, 600) }
	h1 {
		max-width: 100%;
	}
	p {
		max-width: 40em;
	}
`

const Block = styled.div`
	display: block;
	width: 100%;
	position: relative;

	${ ({ background }) => background && `
		position: absolute;
		height: 100%;
		overflow: hidden;
		z-index: 1;
		bottom: 0;
	` }

	${ ({ content, index }) => content && `
		z-index: 3;
	` }
`

const BgImage = styled(Image)`
	height: 100%;
`

const VideoContainer = styled.div`
	transition: opacity ${ animations.mediumSpeed } ease-in-out;
	position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
	z-index: -1;
	pointer-events: none;

	> div > div span {
		display: none;
	}

`

const VideoStyled = styled(Video)`
	z-index: -10;
	.rh5v-DefaultPlayer_controls {
    position: absolute;
		bottom: 0;
		display: none;
		visibility: hidden;
    right: 0;
    left: 0;
    height: 0;
	}
`

const InfoHeader = styled(Grid)`
	padding-top: ${ 100/14 }vw;
	left: 0;
	right: 0;
	${ mq.largerAndUp } {
		${ util.responsiveStyles('margin-top', -28, -21, -18, -18) }
		padding-top: ${ 100/28 }vw;
	}
`

const InfoHeaderContent = styled.div`
	${ util.responsiveStyles('height', 56, 42, 36, 36) }
	display: flex;
	align-items: center;
	justify-content: flex-start;
	p {
		margin: 0;
	}
`

const ProjectAtfContent = styled.div`
	${ util.responsiveStyles('padding-top', 100, 20, 40, 2) }
	${ util.responsiveStyles('padding-bottom', 200, 115, 90, 70) }
`

class ProjectATF extends Component {
	constructor (props) {
		super(props)
		this.state = {
			videoFailed: false,
			scrolled: false
		}
	}

	render () {
		const {
			headline,
			headlineSize,
			text,
			image,
			small,
			medium,
			large,
			video,
			textAlignment,
			hAlignment,
			vAlignment,
			buttons,
			eyebrow,
			showArrow,
			index,
			theme,
			headerArea1,
			headerArea2,
			type,
			pageAccent,
			title,
			tagline
		} = this.props

		const { scrolled } = this.state

		const {
			videoFailed
		} = this.state

		const vAlignOptions = {
			bottom: 'flex-end',
			top: 'flex-start',
			baseline: 'baseline',
			center: 'center'
		}

		const verticalAligment = vAlignOptions[vAlignment]

		return (
			<Wrapper settheme={theme} media={image || video}>
				<Block background>
					<ConditionalRender condition={video}>
						<VideoContainer>
							<VideoStyled
								cover={true}
								loop={true}
								playing={true}
								muted={true}
								controls={['PlayPause']}
								url={video && video.file.url}
							/>
						</VideoContainer>
					</ConditionalRender>
					{!video && image || small ? (
						<BgImage
							image={image.image}
							small={image.small}
							medium={image.medium}
							large={image.large}
						/>
					) : false}
				</Block>
				<Block content="true">
					<InfoHeader
						small="3 [10] 1"
						rowGap="20px"
					>
						<InfoHeaderContent>
							{/*headerArea1 && (
								<ContentfulRichText richText={headerArea1.json}/>
							)*/}
							<p>
								<span style={{ color: pageAccent, fontWeight: 600 }}>{tagline}</span><br/>
								<b>{title}</b>
							</p>
						</InfoHeaderContent>
						<InfoHeaderContent>
							{/*headerArea2 && (
								<ContentfulRichText richText={headerArea2.json}/>
							)*/}
						</InfoHeaderContent>
					</InfoHeader>
					<ProjectAtfContent>
						<Grid
							small="1 [12] 1"
							large="3 [10] 1"
							vAlign="bottom"
							rowGap="5vw"
						>
							<IntroText
								transition={false}
								theme="bgColor"
								eyebrow={eyebrow}
								alignment={textAlignment}
								headlineSize={headlineSize}
								headlineElement="h1"
								headline={headline}
								text={text}
								textSize="bodyMedium"
								buttons={buttons}
								scrolled={scrolled}
								index={index}
							/>
						</Grid>
					</ProjectAtfContent>
				</Block>

			</Wrapper>
		)
	}
}

ProjectATF.defaultProps = {
	textAlignment: 'center',
	hAlignment: 'center',
	vAlignment: 'center',
	showArrow: false,
	headlineSize: 'h3',
	theme: 'black',
	index: false
}

export default ProjectATF
