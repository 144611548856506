import React from 'react'
import styled from '@emotion/styled'
import { colors } from 'src/styles'
import { withPageContext } from 'src/contexts/pageContext'

const ThemeWrapper = styled.div`
	transition: background-color 1.2s ease-in-out, color .75s ease-in-out;
	${ ({ settheme, transitionBg, pageSpecificBg }) => `
		${ settheme && settheme.includes('#') ? `
			background-color: ${ settheme };
		` : `
			${ settheme === 'bgColor' ? `
				${ pageSpecificBg !== 'bgColor' ? `
					background-color: ${ pageSpecificBg };
				` : `
					background-color: transparent;
				` }
			` : `
				background-color: ${ colors[settheme] };
			` }
		` }
	` }

	${ ({ settheme }) =>
		settheme === 'mainColor' ||
		settheme === 'blue' ||
		settheme === 'black' ? `
		color: ${ colors.bgColor };
		h1, h2, h3, h4, h5, h6 {
			color: ${ colors.bgColor };
		}
		p {
			a {
				&:hover {
					color: ${ colors.white };
				}
			}
		}
	` : `` }
`

const ThemeSelector = ({ className, settheme, pageContext, transitionBg, pageSpecificBg, ...rest }) => {
	return (
		<ThemeWrapper
			settheme={settheme}
			className={className}
			transitionBg={transitionBg}
			pageSpecificBg={pageSpecificBg}
			{...rest}
		/>
	)
}

ThemeSelector.defaultProps = {
	transitionBg: true,
	settheme: 'bgColor',
	pageSpecificBg: 'bgColor'
}

export default withPageContext(ThemeSelector)
