import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import Link from 'src/components/Link'
import Logo from 'src/components/Logo'
import Button from 'src/components/Button'
import ScrollListener from 'src/components/ScrollListener'
import { mq, util, animations, colors } from 'src/styles'
import { AiFillMail } from 'react-icons/ai'

const Wrapper = styled(Link)`
	width: ${ 100/14 }%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 6;
	text-align: center;
	margin-top: ${ 100/28 }vw;
	transform: translate3d(0, -50%, 0);
	color: #fff;
	mix-blend-mode: difference;
	a {
		color: inherit;
	}
	svg {
		${ util.responsiveStyles('width', 56, 42, 36, 36) }
		display: inline-block;
		vertical-align: top;
		* {
			fill: currentcolor;
		}
		transition: all .3s ease-in-out;
		${ ({ visible }) => !visible ? `
			transform: translate3d(0, -7vw, 0);
		` : `
			transform: translate3d(0, 0, 0);
		` }
	}
	${ mq.largeAndBelow } {
		transform: none;
		width: auto;
		margin-top: 0;
		padding-left: ${ 100/14 }%;
		padding-top: ${ 100/14 }vw;
		svg {
			${ ({ visible }) => !visible ? `
				transform: translate3d(0, -20vw, 0);
			` : `
				transform: translate3d(0, 0, 0);
			` }
		}
	}
`

const ContactButton = styled(Button)`
	color: ${ colors.white };
	background: transparent;
	svg {
		transition: transform ${ animations.mediumSpeed } ease-in-out;
	}
	&:hover,
	&:focus,
	&:active {
		background: transparent;
		${ mq.mediumAndUp } {
			color: ${ colors.black };
			background: ${ colors.white };
			svg {
				transform: scale(.666);
			}
		}
	}
`

const RightWrapper = styled.div`
	width: ${ 100/14 }%;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 12;
	text-align: center;
	margin-top: ${ 100/28 }vw;
	transform: translate3d(0, -50%, 0);
	mix-blend-mode: difference;
	${ ContactButton } {
		display: none;
		${ util.responsiveStyles('width', 56, 42, 36, 36) }
		display: inline-block;
		vertical-align: top;
		* {
			fill: currentcolor;
		}
		transition: all .3s ease-in-out;
		${ ({ visible }) => !visible ? `
			transform: translate3d(0, -7vw, 0);
		` : `
			transform: translate3d(0, 0, 0);
		` }
		${ mq.largeAndBelow } {
			margin: -6px -10px 0 0;
		}
	}
	${ mq.largeAndBelow } {
		transform: none;
		width: auto;
		margin-top: 0;
		padding-right: ${ 100/14 }%;
		padding-top: ${ 100/14 }vw;
		${ ContactButton } {
			${ ({ visible }) => !visible ? `
				transform: translate3d(0, -20vw, 0);
			` : `
				transform: translate3d(0, 0, 0);
			` }
		}
	}
`

class LogoNav extends Component {
	render () {
		const { className, openDrawer } = this.props
		return (
			<ScrollListener.Consumer>
	      {({ scrolledToTop, scrolledUp, scrolledToBottom }) => (
	      	<Fragment>
			      <Wrapper
			      	to="/"
			      	className={className}
			      	visible={scrolledUp || scrolledToBottom || scrolledToTop}
			      >
			      	<Logo/>
			      </Wrapper>

			      <RightWrapper
			      	to="/"
			      	className={className}
			      	visible={scrolledUp && !scrolledToBottom && !scrolledToTop}
			      >
			      	<ContactButton size="small" shape="circle" onClick={openDrawer}>
			      		<AiFillMail size="30px" />
			      	</ContactButton>
			      </RightWrapper>
		      </Fragment>
	      )}
			</ScrollListener.Consumer>
		)
	}
}

LogoNav.defaultProps = {
	openDrawer: () => {}
}

export default LogoNav
