import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import Grid from 'src/components/Grid'
import Button from 'src/components/Button'
import Link from 'src/components/Link'
import ConditionalRender from 'src/components/ConditionalRender'
import ScrollEntrance from 'src/components/ScrollEntrance'
import ContentfulRichText from 'src/components/ContentfulRichText'
import { typography, colors, mq, util } from 'src/styles'
import BalanceText from 'react-balance-text'

const Wrapper = styled.div`
	display: inline-block;
	display: block;
	vertical-align: top;
	${ util.responsiveStyles('max-width', 1200, 885, 750, 600) }
	${ ({ alignment }) => alignment === 'center' && `
		margin-left: auto;
		margin-right: auto;
		> div {
			margin-left: auto;
			margin-right: auto;
		}
	` }
	${ mq.mediumAndBelow } {
		display: block;
	}
`
const TextContainer = styled(ScrollEntrance)`
	text-align: ${ ({ alignment }) => alignment };
	width: 100%;
	${ ({ alignment }) => alignment === 'center' && `
		margin-left: auto;
		margin-right: auto;
	` }
	${ ({ alignment }) => alignment === 'center' && `
		margin-left: auto;
		margin-right: auto;
	` }
	${ ({ alignment }) => alignment === 'right' && `
		margin-left: auto;
	` }
`

const Eyebrow = styled.h6`
	margin-bottom: 1.2em;
	${ typography.eyebrow }
`

const Headline = styled.h3`
	// font-variant-ligatures: discretionary-ligatures;
	${ ({ headlineSize }) => `
		${ typography[headlineSize] }
		${ headlineSize === 'h1' || headlineSize === 'h2' ? `
			max-width: 14em;
		` : `
			max-width: 18em;
		` }
	` }
	${ ({ alignment }) => alignment === 'center' && `
		margin-left: auto;
		margin-right: auto;
	` }
	${ ({ alignment }) => alignment === 'center' && `
		margin-left: auto;
		margin-right: auto;
	` }
	${ ({ alignment }) => alignment === 'right' && `
		margin-left: auto;
	` }
`

const Text = styled.div`
	p {
		${ ({ textSize }) => typography[textSize] }
		max-width: 40em;
		margin-bottom: 0;
		// &:first-of-type {
		// 	margin-top: 0;
		// }
		${ ({ alignment }) => alignment === 'center' && `
			margin-left: auto;
			margin-right: auto;
			max-width: 38em;
		` }
		${ ({ alignment }) => alignment === 'right' && `
			margin-left: auto;
		` }
	}
`

const ButtonActions = styled.div`
	${ util.responsiveStyles('margin-top', 34, 26, 20, 16) }
	text-align: ${ ({ alignment }) => alignment };
	a, button {
		margin-bottom: 20px;
		${ ({ buttons }) => buttons.length > 1 && `
			min-width: 220px;
			margin-left: 10px;
			margin-right: 10px;
		` }
	}
`

const StyledButton = styled(Button)`
	margin-bottom: 18px;
	min-width: 172px;
	svg {
		display: none;
	}
`

const MediumLink = styled(Link)`
	${ typography.bodyMedium }
`

const TextLockup = ({
		theme,
		eyebrow,
		headline,
		headlineSize,
		text,
		textSize,
		buttons,
		className,
		icon,
		alignment,
		headlineElement,
		additions,
		transition
	}) => {

	const renderHeadline = headline => {
		let parsedHeadline = headline
		parsedHeadline = headline
			.replace(new RegExp('ffi', 'g'), "<span style='letter-spacing: 0'>ffi</span>")
			.replace(new RegExp('ff', 'g'), "<span style='letter-spacing: 0'>ff</span>")
			.replace(new RegExp('fi', 'g'), "<span style='letter-spacing: 0'>fi</span>")
			.replace(new RegExp('fl', 'g'), "<span style='letter-spacing: 0'>fl</span>")
			.replace(new RegExp('⓿', 'g'), "<span style='letter-spacing: -.05em; display: inline-block; vertical-align: middle; margin-bottom: -.175em;'>⓿</span>")
			.replace(new RegExp('❶', 'g'), "<span style='letter-spacing: -.05em; display: inline-block; vertical-align: middle; margin-bottom: -.175em;'>❶</span>")
			.replace(new RegExp('❷', 'g'), "<span style='letter-spacing: -.05em; display: inline-block; vertical-align: middle; margin-bottom: -.175em;'>❷</span>")
			.replace(new RegExp('❸', 'g'), "<span style='letter-spacing: -.05em; display: inline-block; vertical-align: middle; margin-bottom: -.175em;'>❸</span>")
			.replace(new RegExp('❹', 'g'), "<span style='letter-spacing: -.05em; display: inline-block; vertical-align: middle; margin-bottom: -.175em;'>❹</span>")
			.replace(new RegExp('❺', 'g'), "<span style='letter-spacing: -.05em; display: inline-block; vertical-align: middle; margin-bottom: -.175em;'>❺</span>")
			.replace(new RegExp('❻', 'g'), "<span style='letter-spacing: -.05em; display: inline-block; vertical-align: middle; margin-bottom: -.175em;'>❻</span>")
			.replace(new RegExp('❼', 'g'), "<span style='letter-spacing: -.05em; display: inline-block; vertical-align: middle; margin-bottom: -.175em;'>❼</span>")
			.replace(new RegExp('❽', 'g'), "<span style='letter-spacing: -.05em; display: inline-block; vertical-align: middle; margin-bottom: -.175em;'>❽</span>")
			.replace(new RegExp('❾', 'g'), "<span style='letter-spacing: -.05em; display: inline-block; vertical-align: middle; margin-bottom: -.175em;'>❾</span>")
			.replace(new RegExp('❿', 'g'), "<span style='letter-spacing: -.05em; display: inline-block; vertical-align: middle; margin-bottom: -.175em;'>❿</span>")
		return parsedHeadline
	}

	return (
		<Wrapper className={className} alignment={alignment}>
			<div>
				<TextContainer alignment={alignment} transition={transition}>
					<ConditionalRender condition={icon && icon.svgContent}>
						<div style={{ margin: 'auto', width: 50, height: 50, marginBottom: '1em' }} dangerouslySetInnerHTML={{ __html: icon && icon.svgContent }}/>
					</ConditionalRender>
					<ConditionalRender condition={eyebrow}>
						<div><Eyebrow>{eyebrow}</Eyebrow></div>
					</ConditionalRender>

					{headline &&
						<div>
							<Headline headlineSize={headlineSize} as={headlineElement} alignment={alignment}>
								{headlineSize === 'h1' || headlineSize === 'h2' || headlineSize === 'h3' ? (
									<BalanceText>
										<div style={{ display: 'inline' }} dangerouslySetInnerHTML={{ __html: renderHeadline(headline) }}/>
									</BalanceText>
								) : headline}
							</Headline>
						</div>
					}

					{text && text.json && /* ConditionalRender was not working for this */
						<Text textSize={textSize} alignment={alignment}><ContentfulRichText richText={text.json}/></Text>
					}
					
					{text && text.type === 'div' && 
						<Text alignment={alignment}>{text}</Text>
					}

					{typeof text === 'string' &&
						<Text alignment={alignment}><p>{text}</p></Text>
					}

					{additions && (
						additions
					)}
					

					{buttons && (
						<ButtonActions buttons={buttons} alignment={alignment}>
							{buttons.map((button, index) => {
								if (button.style === 'button') {
									return (
										<Button
											key={'button-' + index}
											to={button.to}
											settheme={button.theme}
											external={button.external || false}
											target={button.target || ''}
										>
											{button.label}
										</Button>
									)
								} else {
									return (
										<MediumLink
											key={'button-' + index}
											to={button.to || '/'}
											settheme={button.theme}
											external={button.external || false}
											target={button.target || ''}
											style={button.style}
										>
											{button.label}
										</MediumLink>
									)
								}
							})}
						</ButtonActions>
					)}
				</TextContainer>
			</div>
		</Wrapper>
	)
}

TextLockup.defaultProps = {
	alignment: 'center',
	headlineSize: 'h3',
	transition: true,
	textSize: 'body'
}

export default TextLockup
